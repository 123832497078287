.slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.slider-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.slide {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
}

.slide-item {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    outline: none;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.arrow:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.left-arrow {
    left: 10px;
}

.right-arrow {
    right: 10px;
}